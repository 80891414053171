<template>
  <div class="login-container">
    <div class="login-container-inner">
      <header class="flex">
        <div class="header-logo">
          <img src="@/assets/login-title.jpg" alt="" />
        </div>
        <div class="header-help">
          <a-icon type="question-circle"></a-icon>
          帮助
        </div>
      </header>
      <main class="flex">
        <div class="login-form-left">
          <img src="@/assets/login.jpg" alt="" />
        </div>
        <div class="login-form-right">
          <h3>账号密码登录</h3>
          <a-input placeholder="请输入账号" v-model="account">
            <a-icon slot="prefix" type="user" />
          </a-input>
          <a-input placeholder="请输入密码" type="password" v-model="password">
            <a-icon slot="prefix" type="lock" />
          </a-input>
          <!-- <a-input-search
            placeholder="请输入验证码"
            @search="sendEmail"
            v-model="email"
          >
            <a-icon slot="prefix" type="code" />
            <a-button slot="enterButton" :disabled="flag">
              <span v-if="flag">
                <a-statistic-countdown
                  :value="deadline"
                  style="margin-right: 50px"
                  format="s"
                  @finish="onFinish"
                />(S)
              </span>
              <span v-else>发送验证码</span>
            </a-button>
          </a-input-search> -->
          <a-button block type="primary" style="height: 40px" @click="login"
            >登录</a-button
          >
          <div class="action">
            <!-- <a-checkbox :checked="ifRemeber" @change="changeRemeberMe"
              >记住用户名</a-checkbox
            > -->
            <a></a>
            <a @click="findPwd">忘记密码</a>
          </div>
        </div>
      </main>
      <footer>
        <span> 浙江绿康医养集团股份有限公司 </span>
       <a href="https://beian.miit.gov.cn/" target="_blank"> 浙ICP备14012758号-2</a> 浙公安备案号33010402004484
      </footer>
    </div>
    <a-modal
      title="找回密码"
      :footer="null"
      :closable="false"
      v-model="findPwdModal"
      :width="600"
      centered
    >
      <div class="step-container">
        <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.desc" />
        </a-steps>
      </div>
      <div class="findpwd-form" v-if="current == 0">
        <div class="findpwd-row">
          <div class="findpwd-label">手机号码：</div>
          <div class="findpwd-input">
            <div class="input">
              <a-input
                placeholder="请输入手机号码"
                v-model="findPwdPhone"
              ></a-input>
            </div>
          </div>
        </div>
        <div class="findpwd-row">
          <div class="findpwd-label">验证码：</div>
          <div class="findpwd-input">
            <div class="input">
              <a-input
                placeholder="请输入验证码"
                v-model="findPwdMsg"
              ></a-input>
            </div>
            <div class="button">
              <a-button @click="sendMsg" type="primary" :disabled="sendMsgLoad">
                <a-icon type="message" v-if="!sendMsgLoad"></a-icon>
                <span v-else style="display: flex; align-items: center"
                  ><a-statistic-countdown
                    :value="deadline2"
                    format="s"
                    @finish="onFinish2"
                  />(S)</span
                >
              </a-button>
            </div>
          </div>
        </div>
        <div class="findpwd-row">
          <div class="findpwd-label">设置密码：</div>
          <div class="findpwd-input">
            <div class="input">
              <a-input-password
                placeholder="请输入6-16位字符，不可为纯数字"
                v-model="findPwdNewPwd"
              ></a-input-password>
            </div>
          </div>
        </div>
        <div class="findpwd-row">
          <div class="findpwd-label">确认密码：</div>
          <div class="findpwd-input">
            <div class="input">
              <a-input-password
                placeholder="请再次输入密码"
                v-model="findPwdRepPwd"
              ></a-input-password>
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 22px">
          <a-button type="primary" @click="submit" style="height: 40px"
            >提交</a-button
          >
        </div>
      </div>
      <div v-else>
        <a-result status="success" title="您的密码已成功修改！(5秒后自动关闭)">
        </a-result>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { login } from "@/network/api.js";
export default {
  data() {
    return {
      sendMsgLoad: false,
      deadline2: null,
      findPwdPhone: "",
      findPwdMsg: "",
      findPwdNewPwd: "",
      findPwdRepPwd: "",
      steps: [
        {
          desc: "重置密码",
          key: 1,
        },
        {
          desc: "重置成功",
          key: 1,
        },
      ],
      current: 0,
      findPwdModal: false,
      deadline: null,
      flag: false,
      ifRemeber: false,
      account: "",
      password: "",
      email: "",
    };
  },
  created() {
    this.ifRemeber = localStorage.getItem("remeberMe") == "true";
    this.account = localStorage.getItem("account");
  },
  methods: {
    submit() {
      if (!this.findPwdPhone) {
        return this.$message.error("请输入手机号！");
      }
      if (!this.findPwdMsg) {
        return this.$message.error("请输入验证码！");
      }
      if (!this.findPwdNewPwd) {
        return this.$message.error("请输入新密码！");
      }
      if (!this.findPwdRepPwd) {
        return this.$message.error("请再次输入新密码！");
      }
      const pwdReg = /^[0-9]*$/;
      console.log(this.findPwdNewPwd);
      console.log(this.findPwdNewPwd.length);
      if (
        pwdReg.test(this.findPwdNewPwd) ||
        this.findPwdNewPwd.length > 16 ||
        this.findPwdNewPwd.length < 6
      ) {
        return this.$message.error("密码格式有误！");
      }
      if (this.findPwdNewPwd != this.findPwdRepPwd) {
        return this.$message.error("两次密码输入不一致！");
      }
      this.current = 1;
      this.timer3 = setTimeout(() => {
        this.findPwdModal = false;
      }, 5000);
    },
    onFinish2() {
      this.sendMsgLoad = false;
    },
    sendMsg() {
      if (!this.findPwdPhone) {
        return this.$message.error("请输入手机号！");
      }
      this.$message.success("短信验证码已发送，请注意查收！");
      this.sendMsgLoad = true;
      this.deadline2 = new Date().valueOf() + 60000;
    },
    findPwd() {
      this.current = 0;
      clearTimeout(this.timer3);
      this.timer3 = null;
      this.findPwdPhone = "";
      this.findPwdMsg = "";
      this.findPwdNewPwd = "";
      this.findPwdRepPwd = "";
      this.findPwdModal = true;
    },
    login() {
      if (!this.account.length) {
        return this.$message.warning("请输入账号！");
      }
      if (!this.password.length) {
        return this.$message.warning("请输入密码！");
      }
      // if (!this.email.length) {
      //   return this.$message.warning("请输入验证码！");
      // }

      // 记住用户名
      // if (this.ifRemeber) {
      //   localStorage.setItem("account", this.account);
      // } else {
      //   localStorage.removeItem("account");
      // }
      let pa = {
        password: this.password,
        phone: this.account,
      };
      login(pa).then((res) => {
        if (res.status == 0) {
          localStorage.setItem("nowAccount", this.account);
          this.$router.push({ name: "user" });
        }
        if(res.status ==2000||res.status ==2001){
          this.$message.error("账号或密码错误！")
          
        }
      });
    },
    changeRemeberMe() {
      if (localStorage.getItem("remeberMe") == "true") {
        localStorage.setItem("remeberMe", "false");
      } else {
        localStorage.setItem("remeberMe", "true");
      }
      this.ifRemeber = !this.ifRemeber;
    },
    onFinish() {
      this.flag = false;
    },
    sendEmail() {
      this.flag = true;
      this.deadline = new Date().valueOf() + 1000 * 60;
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
}
.login-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  .login-container-inner {
    width: 948px;
    height: 632px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    header {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      margin-bottom: 30px;
      .header-logo {
        height: 80px;
        img {
          height: 100%;
        }
      }
    }
    main {
      height: 400px;
      margin-bottom: 40px;
      .login-form-left {
        width: 550px;
        text-align: center;
        line-height: 400px;
        img {
          height: 100%;
        }
      }
      .login-form-right {
        flex: 1;
        text-align: center;
        height: 100%;
        background: #fff;
        padding: 0 34px;
        h3 {
          padding: 60px 0 40px;
          margin: 0;
        }
        .action {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
    footer {
      width: 100%;
      height: 54px;
      font-size: 14px;
      color: #4e535a;
      line-height: 54px;
      border-top: 1px solid #e1e2e3;
      border-bottom: 1px solid #e1e2e3;
      text-align: center;
      span {
        padding-right: 20px;
      }
    }
  }
}
.step-container {
  padding: 0 85px;
}
.findpwd-form {
  margin-top: 30px;
  padding: 0 85px;

  .findpwd-row {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    height: 40px;
    .findpwd-label {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .findpwd-input {
      width: 300px;
      display: flex;
      .input {
        flex: 1;
        input {
          height: 40px;
        }
        span {
          ::v-deep input {
            height: 40px;
          }
        }
      }
      .button {
        button {
          height: 40px;
          margin-left: 10px;
          span {
            .ant-statistic {
              ::v-deep.ant-statistic-content {
                color: rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
    }
  }
}
</style>>
